import {React, useEffect} from "react"
import { graphql,  Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import parse from "html-react-parser"
// import Background from "../images/mobile-hero.jpg"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Revs from "../components/reviews"

import SliderPhoto from '../components-pl/slider'
import SliderIn from '../components/slider2'
import SliderDrinks from '../components/slider-drinks'
import BlogModule from '../components/post-module'



const Home = ({ data, location }) => {

  useEffect(() => {



    window.onscroll = function() {
      if(window.scrollY >= 45){
      document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px';
      document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'fixed'
    }else{document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px'
    document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'relative'
    }
    let mybtn = document.getElementById("back-to-top");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybtn.style.display = "block";
      } else {
        mybtn.style.display = "none";
      }

      };


  })
  
  const siteTitle = data.site.siteMetadata.title
  //const energyDrinks = data.allWpProduct.edges
  const blogPosts = data.allWpPost.edges
  // console.log(blogPosts)
  return (
    <>
    <Layout data={data} location={location} title={siteTitle}>
      <Seo
      title="Shroom - the natural energy drink with cordyceps, lion's mane and l'theanine"
      description="Lion's Mane, Cordyceps, and L-Theanine: Natural Supplements for Cognitive Support, Immune Boosting, and Stress Reduction. Shop our range of Natural Energy Drinks, Apparel, and Accessories."
       />
       <section className="hero is-white" style={{top:'0px',height:'955px'}}>
         <div id="hero" className="mask container-fluid" style={{zIndex:'1',top:'100px',position:'absolute',backgroundColor: 'rgba(0, 0, 0, 0.4)',display:'flex',alignItems:'center',flexWrap:'wrap',width:'100%',minWidth:'100%',height:'800px',justifyContent:'center'}}>
           <div className="col-8" style={{minHeight: '400px',display:'block',textAlign:'center'}}>
           <span className="spec-h"></span>
           <h1 className="text-white mob-hero spec-h" style={{textAlign:'center',fontSize:'5em',wordBreak:'break-word'}}>shroom drink</h1>
           <br />
           <h2 className="spec-h text-white" style={{textAlign:'center',fontSize:'1.2em'}}>The natural energy drink with cordyceps, lion's mane and l'theanine</h2>
           <p className="text-white" style={{textAlign:'center',margin:'auto',maxWidth:'500px',color:'#111'}}>
           <br /><br />
           <br />
           <a href="#mushroom-drinks" className="btn btn-outline-light spec-h btn-lg" style={{fontVariant:'small-caps',margin:'10px'}}>
             Learn more&nbsp;<span role="img" aria-label="mushroom">🍄</span>
           </a>
           <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com" className="btn btn-outline-light spec-h btn-lg" style={{fontVariant:'small-caps',margin:'10px'}}>
             Visit shop&nbsp;<span role="img" aria-label="shop">🛒</span>
           </a>
           </p>
           <br />
             <p className="spec-h text-white" >Learn more about our amazing drinks!</p>
             <div id="scroll-to" style={{padding:'2em',textAlign:'center',color:'white'}}>
             <a href="#scroll-to" style={{fontSize:'1.5em'}}>⇩</a>
             </div>
           </div>
         </div>
       <video className="video-background" id="video" style={{top:'100px',position:'absolute',objectPosition:'center',objectFit:'cover',width:'100%',minWidth:'100%',height:'800px',zIndex:'0'}} autoPlay playsInline muted loop>
       <source src="https://admin.shroom4you.com/wp-content/uploads/2023/10/shroom-promo.mp4" type="video/mp4" />
       Your browser does not support the video tag.
       </video>
     </section>





    <SliderPhoto />
    <br id="mushroom-drinks" />
    <section className="is-white">
    <div className="content has-text-centered" style={{padding:'30px',paddingTop:'75px',borderRadius:'0px'}}>
    <h2 className="title spec-h">Visit our store</h2>
    <p>Natural & healthy, innovative, functional mushroom drinks, clothing and accessories.
    <br />
    </p>
    <br />
    <a href="https://shop.shroom4you.com/#archive" className="btn btn-outline-dark spec-h">
      SHOP&nbsp;<span role="img" aria-label="mushroom">🍄</span>
    </a>
        <br /><br />
    </div>
    </section>
    <br /><br />
    <SliderDrinks />
    <br /><br />
    <div style={{marginTop:'50px',display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
      <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/shroomscriptions/">
        <div class="home-mod" style={{position: 'relative', maxWidth:'500px', overflow: 'hidden', padding: '1em', margin: '1em auto'}}>
        {/* Overlay container */}
        <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
          <h2 className="spec-h home-m text-white">Shroomscriptions</h2>
          <p>Never run out of&nbsp;<span className="spec-h">shroom</span> again. <br /><b>Subscription plans!</b>
          </p>

        </div>

          <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/shroomscriptions.jpg"
            alt=""
            loading='lazy'
            style={{
               gridArea: "1/1",
               width:'100%',
               objectFit:'cover',
               minHeight:'300px',
               maxWidth: '100%',
               boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
               borderRadius:'25px',
             }}
             />
        </div>
      </a>
      <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/#accessories">
      <div class="home-mod" style={{position: 'relative', maxWidth:'500px', overflow: 'hidden', padding: '1em', margin: '1em auto'}}>

      {/* Overlay container */}
      <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
        <h2 className="spec-h home-m  text-white">Accessories</h2>
        <p>The original <span className="spec-h">shroom</span> accessories. <b>Mushroomly!</b> </p>
      </div>

        <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/acc.jpg"
          alt=""
          loading="lazy"
          style={{
             gridArea: "1/1",
             width:'100%',
             objectFit:'cover',
             minHeight:'300px',
             maxWidth: '100%',
             boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
             borderRadius:'25px',
           }}
           />
      </div>
      </a>
    </div>

    <div className="mt-5 container-fuild has-text-centered" style={{alignItems:'center',justifyContent:'center',textAlign:'center',margin:'5em 0em',padding:'2em 1em'}}>
    <h2 className="spec-h">
    Learn more about our ingredients!
    </h2>
    <p>
    Shroom drinks are real superfoods, or more likely superdrinks! Check out their amazing ingredients.
    </p>
    <br /><br />
    <SliderIn />
    </div>


    <Revs />
    <br /><br />
    <BlogModule />
    <br />
    <p style={{textAlign:'center'}}>
    <Link to="/blog/" className="btn btn-outline-dark mush">Visit blog</Link>
    </p>
    <br /><br />
    </Layout>
    </>
  )
}

export default Home

export const pageQuery = graphql`
query GetEnergyDrinks {
  site {
    siteMetadata {
      title
    }
  }
  allWpPost(sort: { fields: [date], order: DESC } limit: 2,filter: {language: {code: {eq: EN}}}) {
    edges {
      node {
        id
        date(formatString: "DD.MM.YYYY")
        link
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        excerpt
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        categories {
         nodes {
           name
           uri
         }
       }
       tags {
          nodes {
            name
          }
        }
      }
    }
  }
}
`
