import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"


const BlogModule = () => {

  const {
    allWpPost,
  } = useStaticQuery(graphql`
    query blogcompQuery {
      allWpPost(filter: {language: {code: {eq: EN}}}, sort: {fields: dateGmt, order: DESC}, limit: 2) {
        edges {
          node {
            title
            link
            excerpt
            dateGmt
            date(formatString: "DD.MM.YYYY")
            categories {
              nodes {
                name
                uri
              }
            }
            author {
              node {
                avatar {
                  url
                }
                name
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  id
                  childImageSharp {
                    gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
              }
            }
          }
        }
      }
    }
  `)


  return (
    <>
    <h2 className="spec-h" style={{textAlign:'center',maxWidth:'90%',marginLeft:'5%'}}>Read more on the blog!</h2>
    <p style={{textAlign:'center',maxWidth:'90%',marginLeft:'5%'}}>Read about mushrooms and science behind them on our blog.</p>
    <div className="row container-fluid blog-mod"  style={{textAlign:'center',margin:'auto',justifyContent:'center'}}>
      {allWpPost.edges.map(post => {
        return (
        <>
        <div className="col-5 card mush" style={{textAlign:'center',margin:'.5em',padding:'15px',minHeight:'700px',boxShadow:'0 4px 30px rgba(0, 0, 0, 0.1)'}}>
        <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',alignItems:'center',margin:'0',padding:'0'}}>
        <Link className="has-text-link-dark" to={post.node.categories.nodes[0].uri}>{post.node.categories.nodes[0].name}</Link>
        <small style={{border:'1px solid #1D1D1D',color:'#1D1D1D',margin:'10px 0px',padding:'0 10px',borderRadius:'3px'}}>{post.node.date}</small>
        </div>
        <Link to={post.node.link}>
        <GatsbyImage
          image={post.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          alt={post.node.featuredImage.node.altText ? post.node.featuredImage.node.altText : post.node.title}
          style={{ minWidth:'200px',maxWidth:'500px', minHeight:'auto',borderRadius:'20px' }}
          imgStyle={{minWidth:'200px',maxWidth:'500px',minHeight:'auto',borderRadius:'20px', objectFit:'contain'}}
          loading="lazy"
          className="blog-mod-img"
        />
        </Link>
        <p className="spec-h" style={{paddingTop:'15px',fontSize:'1.5em',margin:'auto',textAlign:'left'}}>{post.node.title}</p>

        <p style={{textAlign:'left',fontSize:'.9em',margin:'auto'}}>{parse(post.node.excerpt)}</p>
        <Link style={{maxWidth:'300px',margin:'auto'}} className="btn"  ariaLabel={post.node.link} to={post.node.link}>Read article &nbsp;<span role="img" aria-label="mushroom">🍄</span></Link>

        <p className="is-small" style={{marginTop:'20px',textAlign:'left'}}> By:&nbsp;<b>{parse(post.node.author.node.name)}</b></p>
        </div>
        </>
        )
      })}
    </div>
    </>
  );
};

export default BlogModule;
